import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const TimeInfo = styled.span``;

function getOrdinalNum(n) {
  return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
}

function formatTime(d) {
  const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][d.getDay()];
  const dayNr = getOrdinalNum(d.getDate());
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][d.getMonth()];
  const year = d.getFullYear();
  const hours = d
    .getHours()
    .toString()
    .padStart(2, '0');
  const minutes = d
    .getMinutes()
    .toString()
    .padStart(2, '0');
  return (
    <TimeInfo>
      <span>{`${dayName}, ${dayNr} ${month} ${year} `}</span>
      {`${hours}:${minutes}`}
    </TimeInfo>
  );
}

function Time() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const tick = () => setTime(new Date());
    const interval = setInterval(tick, 10 * 1000);
    return () => clearInterval(interval);
  }, [setTime]);

  return formatTime(time);
}

export default Time;
