import React from 'react';

const Context = React.createContext(null);
export function MqttMessagesProvider({ children }) {
  const provider = useMqttMessagesProvider();
  return <Context.Provider value={provider}>{children}</Context.Provider>;
}

export function useMqttMessages() {
  return React.useContext(Context);
}

export const messageActions = {
  ToggleShufflePlayback: 'ToggleShufflePlayback',
  PlayNextTrack: 'PlayNextTrack',
  PlayPreviousTrack: 'PlayPreviousTrack',
  PlaySelectedTrack: 'PlaySelectedTrack',
  ShuffleTrackInsert: 'ShuffleTrackInsert',
  ShuffleTrackUpdate: 'ShuffleTrackUpdate',
  ShuffleTrackDelete: 'ShuffleTrackDelete',
  ShuffleUpdate: 'ShuffleUpdate',
  BulletinAdded: 'BulletinAdded',
  BulletinUpdated: 'BulletinUpdated',
  BulletinDeleted: 'BulletinDeleted',
  DeviceUpdated: 'DeviceUpdated',
  DeviceClear: 'DeviceClear',
  DeviceDeleted: 'DeviceDeleted',
  ChatCreated: 'ChatCreated',
};

function mqttMessagesReducer(state, event) {
  let updatedState = { ...state };
  switch (event.type) {
    case 'subscribe':
      updatedState.subscriptions[event.messageAction] = [
        ...(state.subscriptions[event.messageAction] || []),
        event.handler,
      ];
      return updatedState;
    case 'unsubscribe':
      updatedState.subscriptions[event.messageAction] = (state.subscriptions[event.messageAction] || []).filter(
        handler => handler !== event.handler
      );
      return updatedState;
    default:
      return state;
  }
}

function useMqttMessagesProvider() {
  const [state, dispatch] = React.useReducer(mqttMessagesReducer, { subscriptions: {} });

  function processMqttMessage({ source, action, payload }) {
    if (source === 'tv') {
      return;
    }

    (state.subscriptions[action] || []).forEach(handler => {
      handler(payload);
    });
  }

  function subscribeToMqttMessages(messageAction, handler) {
    if (!messageActions[messageAction]) {
      console.warn('Tried to subscribe to unsupported action: ', messageAction);
      return () => {};
    }
    dispatch({ type: 'subscribe', messageAction, handler });
    return () => unsubscribeFromMqttMessages(messageAction, handler);
  }

  function unsubscribeFromMqttMessages(messageAction, handler) {
    dispatch({ type: 'unsubscribe', messageAction, handler });
  }

  return {
    processMqttMessage,
    subscribeToMqttMessages,
  };
}
