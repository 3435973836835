import React from 'react';
import axios from 'axios';
import { useAuthentication } from './useAuthentication';
import config from '../config';

const Context = React.createContext(null);
export function WeatherProvider({ children }) {
  const provider = useWeatherProvider();
  window['$weatherState'] = provider;

  return <Context.Provider value={provider}>{children}</Context.Provider>;
}
export function useWeather() {
  return React.useContext(Context);
}

function useWeatherProvider() {
  const {
    state: {
      authentication: {
        device: { city },
      },
    },
  } = useAuthentication();
  const [weather, setWeather] = React.useState({
    min: null,
    max: null,
    temp: null,
    city: '',
    weather: '',
    icon: '',
  });
  const [weatherError, setWeatherError] = React.useState(null);

  React.useEffect(() => {
    const REFRESH_MINUTES = 10;
    async function fetchWeather() {
      try {
        const { data } = await axios.get(
          `https://api.openweathermap.org/data/2.5/weather?q=${city}&APPID=${config.weatherAppId}&units=metric`
        );

        setWeather({
          min: data.main.temp_min,
          max: data.main.temp_max,
          temp: data.main.temp,
          city: data.name,
          weather: data.weather[0].main,
          icon: `//openweathermap.org/img/w/${data.weather[0].icon}.png`,
        });
      } catch (error) {
        console.error('fetchWeather', error);
        setWeatherError(error);
      }
    }

    city && fetchWeather();
    const interval = city && setInterval(fetchWeather, REFRESH_MINUTES * 60 * 1000);

    return () => clearInterval(interval);
  }, [city]);

  return { weather, weatherError };
}
