import React from 'react';
import styled from 'styled-components';

const Subtitle = styled.h2`
  font-size: 20px;
  font-weight: 100;
  padding: 0;
  margin: 0;
`;

const Title = styled.h1`
  font-size: 40px;
  padding: 0;
  margin: 0;
`;

const Message = styled.div`
  display: inline-block;
  text-align: center;
  color: #0c0c91;
`;

function Bulletin(props) {
	const {headline, content} = props;

	return (
		<Message>
			<Title dangerouslySetInnerHTML={{__html: headline}}/>
			<Subtitle dangerouslySetInnerHTML={{__html: content}}/>
		</Message>
	);
}

export default Bulletin;
