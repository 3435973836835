import React from 'react';
import styled from 'styled-components';
import { FiArrowRight } from 'react-icons/fi';
import { useWeather } from '../hooks/useWeather';

const WeatherIcon = styled.img`
  padding: 0;
  margin: 0 5px 0 0;
  height: 20px;
  float: left;
  filter: grayscale(1);
`;

const WeatherInfo = styled.div`
  font-size: 11px;
  color: #0c0c91;

  svg {
    margin-bottom: -2px;
    margin-right: 3px;
  }
`;

function displayWeather(weather) {
  const tempRange =
    parseInt(weather.min) !== parseInt(weather.max) ? (
      <span>
        <FiArrowRight />
        {parseInt(weather.max)}°C,{' '}
      </span>
    ) : null;

  return (
    <WeatherInfo>
      <WeatherIcon src={weather.icon} />
      <span>
        {`${weather.city} ${parseInt(weather.min)}°C `}
        {tempRange}
        {weather.weather}
      </span>
    </WeatherInfo>
  );
}

function Weather() {
  const { weather } = useWeather();

  return weather.min && Number.isFinite(weather.min) ? displayWeather(weather) : null;
}

export default Weather;
