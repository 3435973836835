import { put, takeEvery } from 'redux-saga/effects';
import config from '../config';
import axios from 'axios';
import * as actionTypes from '../constants/actionTypes';
import { disconnectMQTTClient } from '../services/mqtt';
import aws4 from 'aws4';
import { getTokens } from '../services/auth';

axios.interceptors.request.use(config => {
  const newConfig = config;
  delete newConfig.headers.Host;
  return newConfig;
});

export function* fetchLjubljanaBusInfo(action) {
  try {
    const stationId = action.stationId;
    const response = yield axios(
      aws4.sign(
        {
          url: `${config.host}${config.basePath}/trola?postaja=${stationId}`,
          path: `${config.basePath}/trola?postaja=${stationId}`,
          host: config.bareHost,
          region: config.awsRegion,
          service: 'execute-api',
        },
        getTokens()
      )
    );

    if (!response.data) {
      fetchLjubljanaBusInfo(action);
      return null;
    }
    yield put({
      type: actionTypes.SET_BUS_INFO,
      data: { [stationId]: response.data },
    });
  } catch (error) {
    handleError(error);
    fetchLjubljanaBusInfo(action);
    console.log('fetchLjubljanaBusInfo', error);
  }
}

export function reloadApp() {
  window.location.reload(true);
}

export function* switchEnvironment(action) {
  try {
    const response = yield axios(
      aws4.sign(
        {
          url: `${config.host}${config.basePath}/devices/logout`,
          path: `${config.basePath}/devices/logout`,
          host: config.bareHost,
          region: config.awsRegion,
          service: 'execute-api',
          method: 'GET',
        },
        getTokens()
      )
    );
    if (response.data) {
      localStorage.clear();
      disconnectMQTTClient();
      localStorage.setItem('env', action.env);
      setTimeout(() => window.location.reload(true), 2000);
    } else {
      disconnectAndRefresh();
    }
  } catch (error) {
    handleError(error);
    disconnectAndRefresh();
    console.log('switchEnvironment', error);
  }
}

export function handleError(error) {
  if (error && error.response && error.response.status === 403) {
    localStorage.clear();
    disconnectMQTTClient();
    setTimeout(() => window.location.reload(true), 2000);
  }
}

function disconnectAndRefresh() {
  localStorage.clear();
  disconnectMQTTClient();

  setTimeout(() => window.location.reload(true), 2000);
}

export default function* rootSaga() {
  // yield takeEvery(actionTypes.FETCH_LJUBLJANA_BUS_INFO, fetchLjubljanaBusInfo);
  yield takeEvery(actionTypes.RELOAD_APP, reloadApp);
  yield takeEvery(actionTypes.SWITCH_ENVIRONMENT, switchEnvironment);
}
