import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Countdown from './countdown/Countdown';

const CountdownContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
  text-align: center;
  backface-visibility: hidden;
`;
const Heading = styled.h1`
  color: #0c0c91;
  font-size: 1em;
  font-weight: bold;
  padding: 0;
  margin: 0;
`;

export const KNOWABUNGA_DATE = Date.parse('2024-10-18 15:00 GMT+02');

export function KnowabungaCountdown({ date = KNOWABUNGA_DATE }) {
  const days = moment(date).diff(moment(), 'days');
  const hours = moment(date).diff(moment(), 'hours');
  const showDays = days > 2;
  return (
    <CountdownContainer>
      <Heading>{showDays ? 'Days' : 'Hours'} left to Knowabunga 2024</Heading>
      <Countdown number={showDays ? days : hours} />
    </CountdownContainer>
  );
}
