import { keyframes } from 'styled-components';

export const fadeInAndOut = keyframes`
  0% {
    opacity:0;
    transform: translate3d(-100%, 0, 0);
  }
  10% {
    opacity:1;
    transform: translate3d(0, 0, 0);
  }
  90% {
    opacity:1;
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity:0;
    transform: translate3d(100%, 0, 0);
  }
`;
