import React from 'react';
import styled from 'styled-components';
import { useBulletins } from '../hooks/useBulletins';
import useKeydown from '../hooks/useKeydown';
import { fadeInAndOut } from '../utils/animations';
import Bulletin from './Bulletin';

const BulletinsContainer = styled.div`
  height: 100vh;
  width: 100%;
`;

const BulletinContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeInAndOut} 20s ease-in-out 1;
`;

const actionTypes = {
  setItems: 'setItems',
  tick: 'tick',
};
const initialState = {
  currentIndex: 0,
  activeBulletins: [],
};

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.setItems:
      const { activeBulletins } = action;
      const activeItem = activeBulletins[state.currentIndex] || { id: '' };

      const activeItemIndex = activeBulletins.findIndex(bulletin => bulletin.id === activeItem.id);

      return {
        ...state,
        activeBulletins,
        currentIndex: activeItemIndex === -1 ? 0 : activeItemIndex,
      };
    case actionTypes.tick:
      return { ...state, currentIndex: (state.currentIndex + 1) % state.activeBulletins.length };
    default:
      return state;
  }
}

function Bulletins() {
  // we can't get here if we don't even have activeBulletins
  const { activeBulletins } = useBulletins();

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { currentIndex } = state;
  const activeItem = activeBulletins[currentIndex] || { id: '' };

  useKeydown(() => {
    // 78=n
    dispatch({ type: actionTypes.tick });
  }, [78]);

  React.useEffect(() => {
    dispatch({
      type: actionTypes.setItems,
      activeBulletins: activeBulletins && activeBulletins.length ? activeBulletins : [],
    });
  }, [activeBulletins]);

  return (
    <BulletinsContainer>
      <BulletinContainer key={activeItem.id} onAnimationEnd={() => dispatch({ type: actionTypes.tick })}>
        <Bulletin {...activeItem} />
      </BulletinContainer>
    </BulletinsContainer>
  );
}

export default Bulletins;
