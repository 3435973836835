import React from 'react';
import { useManualQuery } from 'graphql-hooks';

import { useAuthentication } from './useAuthentication';
import { parseGraphQLApiErrors } from '../helpers/errorHelpers';
import { preparePeopleInOfficeSlide, prepareCountdown } from '../helpers/contentFeed';
import { KNOWABUNGA_DATE } from '../components/KnowabungaCountdown';

const LJUBLJANA_PROD_OFFICE_ID = '07226e30-82b1-11e9-8485-0f7206b4e95b';

async function getInOffice() {
  const response = await fetch('https://api.1337co.de/socialize/ljubljana?id=07226e30-82b1-11e9-8485-0f7206b4e95b');
  const text = await response.text();
  const data = JSON.parse(text);
  return data;
}

const GET_FEED = `
  query getFeed($deviceId: String!) {
    getFeed(deviceId: $deviceId) {
      ... on Tweet {
        design
        id
        type
        createdAt
        text
        image
        author {
          name
        }
      }
      ... on RssItem {
        id
        type
        title
        date
        author {
          name
          avatarUrl
        }
      }
    }
  }
`;
const FETCH_INTERVAL = 15 * 60 * 1000; // 15 minutes

function makeSlides(feedItems, inOffice) {
  return feedItems.reduce((acc, current, index) => {
    if (inOffice.length && index % 1 === 0) {
      acc.push(preparePeopleInOfficeSlide(inOffice, index));
    }
    if (index % 2 === 0 && Date.now() < KNOWABUNGA_DATE) {
      acc.push(prepareCountdown(index));
    }
    acc.push(current);
    return acc;
  }, []);
}

export function useFeed() {
  const {
    state: { authentication },
  } = useAuthentication();

  const { id, city, officeId, rssFeeds, twitterAccounts } = authentication.device;

  const [queryFn] = useManualQuery(GET_FEED);
  const [feedItems, setFeedItems] = React.useState([]);
  const [inOffice, setInOffice] = React.useState([]);

  const mounted = React.useRef(true);
  React.useEffect(() => () => (mounted.current = false), []);

  React.useEffect(() => {
    async function fetchInOffice() {
      if (officeId === LJUBLJANA_PROD_OFFICE_ID || city === 'Ljubljana') {
        getInOffice().then(data => {
          if (mounted.current) setInOffice(data);
        });
      }
    }
    const interval = setInterval(() => fetchInOffice(), FETCH_INTERVAL);
    fetchInOffice().catch(console.error);

    return () => clearInterval(interval);
    // ignoring dependency: city
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeId]);

  React.useEffect(() => {
    async function fetchFeed() {
      if (!id) return;
      const result = await queryFn({ variables: { deviceId: id } });
      const { getFeed } = (result || {}).data;
      if (mounted.current) {
        setFeedItems(getFeed || []);
      }
      if (result.error) {
        throw parseGraphQLApiErrors(result.error);
      }
    }
    const interval = setInterval(() => fetchFeed(), FETCH_INTERVAL);
    fetchFeed().catch(console.error);

    return () => clearInterval(interval);
  }, [rssFeeds, id, twitterAccounts, queryFn]);

  // Careful: memo is needed because we dispatch actionTypes.setItems everytime this changes.
  return React.useMemo(() => makeSlides(feedItems || [], inOffice || []), [feedItems, inOffice]);
}
