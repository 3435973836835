import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {useBulletins} from '../hooks/useBulletins';
import {useLeetVideo} from '../hooks/useLeetVideo';
import Bulletins from './Bulletins';
import BusContainer from './bus/BusContainer';
import Clock from './Clock';
import {Feed} from './Feed';
import InfoPanel from './InfoPanel';
import LeetCountdown from './LeetCountdown';

const Container = styled.div`
  width: 100vw;
  height: 100%;
`;

function CentralContainer(props) {
	const {mode} = props;

	const {video} = useLeetVideo();

  return <Container>{video ? <LeetCountdown /> : <DefaultView mode={mode} />}</Container>;
}

function DefaultView(props) {
	const {mode} = props;

	const {activeBulletins} = useBulletins();
	const showBulletins = mode === 'events' && activeBulletins.length;

	return (
		<>
			{!!showBulletins && <Bulletins/>}
			<Feed isOpened={mode === 'events' && activeBulletins.length === 0} handleKeyPress={!showBulletins}/>
			<Clock isOpened={mode === 'clock'}/>
			<BusContainer isOpened={mode === 'bus'}/>
			<InfoPanel isOpened={mode === 'info'}/>
		</>
	)
}

function mapStateToProps(state) {
	return {
		mode: state.mode,
	};
}

export default connect(mapStateToProps, undefined)(CentralContainer);
