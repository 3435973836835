const vertexShaderSource = `
attribute vec4 aVertexPosition;
attribute vec2 aTextureCoord;

uniform mat4 uModelViewMatrix;
uniform mat4 uProjectionMatrix;

varying highp vec2 vTextureCoord;

void main(void) {
  gl_Position = uProjectionMatrix * uModelViewMatrix * aVertexPosition;
  vTextureCoord = aTextureCoord;
}
`;

const fragmentShaderSource = `
precision mediump float;
varying highp vec2 vTextureCoord;

uniform sampler2D uSampler;

uniform vec2 iResolution;
uniform vec2 direction;

vec4 grayScale(vec4 color) {
  float grey = color.r * 0.299 + color.g * 0.587 + color.b * 0.114;
  return vec4(grey, grey, grey, color.a);
}

vec4 multiplyGreen(vec4 color) {
  vec4 values = vec4(1.0, 1.0, 1.0, 1.0);
  return color * values;
}

float scale (float scale, float number) {
  return (number * scale > 1.0) ? 1.0 : number * scale;
}

vec4 brighten(float factor, vec4 color) {
  return vec4(
    scale(color.r, factor),
    scale(color.g, factor),
    scale(color.b, factor),
    color.a
  );
}

float brightness (vec4 color) {
  return color.r + color.g + color.b;
}

vec4 applyBlue (vec4 color) {
  vec4 green = vec4(1.0, 1.0, 1.0, 1.0);
  vec4 blue = vec4(0.934, 0.902, 0.973, 1.0) * 0.95;
  return brightness(color)  >= brightness(blue)
    ? color
    : blue;
}

vec4 applyBlueGreen (vec4 color) {
  vec4 green = vec4(1.0, 1.0, 1.0, 1.0);
  vec4 blue = vec4(0.934, 0.902, 0.973, 1.0) * 0.95;
  vec4 greenShade = color * green;
  vec4 nColor = blue + brightness(color) / 3.0 * (green - blue);
  return vec4(nColor.rgb, 1.0);
  /*
  if (brightness(greenShade) <= brightness(blue)) {
    return blue;
  } else {
    vec4 nColor = blue + (brightness(color) - brightness(blue)) / 3.0 * (green - blue);
    return vec4(nColor.rgb, 1.0);
  }
  */
}

vec4 leetFilter (vec4 color) {
  // return applyBlue(multiplyGreen(brighten(1.4, grayScale(color))));
  return applyBlueGreen(brighten(1.0, grayScale(color)));
}
 
float random(vec3 scale, float seed) {
  /* use the fragment position for a different seed per-pixel */
  return fract(sin(dot(gl_FragCoord.xyz + seed, scale)) * 43758.5453 + seed);
}

vec4 blur2 (sampler2D texture, vec2 texCoord, vec2 dir, float radius) {
  float delta = radius / 1080.0;
  vec4 color = vec4(0.0);
  float total = 0.0;

  /* randomize the lookup values to hide the fixed number of samples */
  float offset = random(vec3(12.9898, 78.233, 151.7182), 0.0);
  for (float t = -5.0; t <= 5.0; t++) {
      float percent = (t + offset - 0.5) / 5.0;
      float weight = 1.0 - abs(percent);
      vec4 sample = texture2D(texture, texCoord + delta * percent);

      /* switch to pre-multiplied alpha to correctly blur transparent images */
      sample.rgb *= sample.a;
   
      color += sample * weight;
      total += weight;
  }

  color = color / total;

  /* switch back from pre-multiplied alpha */
  color.rgb /= color.a + 0.00001;
  return leetFilter(color);
}

void main(void) {
  vec2 resolution = vec2(240.0, 180.0);
  // vec2 resolution = vec2(1080.0, 608.0);
  vec4 color = texture2D(uSampler, vTextureCoord);
  gl_FragColor = leetFilter(color);
  return;
  vec2 uv = vec2(gl_FragCoord.xy / resolution.xy);
  if (uv.x > 0.5) {
    gl_FragColor = vec4(vec3(0.0), 1.0);
    return;
  }
  gl_FragColor = blur2(uSampler, vTextureCoord, vec2(1.0, 1.0), 15.0);
}
`;

const loadShader = (gl, type, source) => {
  const shader = gl.createShader(type);

  // Send the source to the shader object

  gl.shaderSource(shader, source);

  // Compile the shader program

  gl.compileShader(shader);

  // See if it compiled successfully

  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    alert('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
    gl.deleteShader(shader);
    return null;
  }

  return shader;
};

const initShaderProgram = gl => {
  const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);

  const shaderProgram = gl.createProgram();
  gl.attachShader(shaderProgram, vertexShader);
  gl.attachShader(shaderProgram, fragmentShader);
  gl.linkProgram(shaderProgram);

  // If creating the shader program failed, alert
  if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
    throw new Error(`Unable to initialize the shader program: ${gl.getProgramInfoLog(shaderProgram)}`);
  }

  return shaderProgram;
};

export { initShaderProgram };
