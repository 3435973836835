import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import Countdown from './countdown/Countdown';
import { useLeetVideo } from '../hooks/useLeetVideo';
import { leetTimeOfDay } from '../constants/timepoint';

const CountdownContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
  text-align: center;
  backface-visibility: hidden;
`;
const TitleText = styled.span`
  text-align: center;
  font-size: 0.5em;
  color: white;
  font-weight: bold;
`;
const TalkTitle = styled.span`
  color: white;
  font-size: 0.4em;
  padding-top: 5px;
  div {
    opacity: 0.6;
  }
`;

function LeetCountdown() {
  const { video, playVideo } = useLeetVideo();
  const [hours, minutes, seconds = 0] = leetTimeOfDay();
  const leetSecondsLeft = moment().hours(hours).minutes(minutes).seconds(seconds).diff(moment(), 'seconds');
  const [countdown, setCountdown] = React.useState(leetSecondsLeft);

  React.useEffect(() => {
    if (countdown <= 0) {
      playVideo();
    }
    // skipped in dependency array below: playVideo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  React.useEffect(() => {
    if (video) {
      const timeout = setInterval(() => setCountdown(countdown => countdown - 1), 1000);
      return () => clearInterval(timeout);
    }
  }, [video]);

  return video && countdown !== 0 ? (
    <CountdownContainer>
      <TitleText>Countdown to 1337'o clock</TitleText>
      <Countdown number={countdown} />
      <TalkTitle>
        <div>Todays knowledge injection:</div>
        {video.title}
      </TalkTitle>
    </CountdownContainer>
  ) : null;
}

export default LeetCountdown;
