import React from 'react';
import posed from 'react-pose';
import styled from 'styled-components';

import { useAuthentication } from '../../hooks/useAuthentication';
import LjubljanaBusModule from './LjubljanaBusModule';
import MiniWeather from '../MiniWeather';

function Time() {
  const time = useTime();
  return <TimeInfo>{time}</TimeInfo>;
}

const Container = styled(
  posed.div({
    open: { top: 0 },
    closed: { top: '200%' },
  })
)`
  margin: 15vh auto;
  left: 0;
  position: absolute;
  width: 80vw;
  margin: 15vh 10vw;
  padding: 1.5% 2%;
  height: 70vh;
  display: flex;
  border-radius: 10px;
  background-color: rgba(0, 43, 85, 0.93);
  font-size: 10px;
  transition: opacity 0.25s ease-in-out;
  will-change: opacity;
  color: white;
  box-sizing: border-box;
`;
const TimeInfo = styled.span`
  position: absolute;
  font-size: 45px;
  top: -55px;
`;

const NoModule = styled.div`
  font-size: 24px;
  text-align: center;
  width: 100%;
  margin: 100px 0px;
`;

function useTime() {
  const [time, setTime] = React.useState(new Date());
  React.useEffect(() => {
    const updateTime = () => setTime(new Date());
    const timeout = setTimeout(updateTime, 10000);
    return () => clearTimeout(timeout);
  }, [time]);

  return `${time
    .getHours()
    .toString()
    .padStart(2, '0')}:${time
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
}

function BusContainer({ isOpened }) {
  const {
    state: {
      authentication: {
        device: { city },
      },
    },
  } = useAuthentication();

  return (
    <Container pose={isOpened ? 'open' : 'closed'}>
      <Time />
      <MiniWeather show={isOpened} />
      {getModuleForBus(city)}
    </Container>
  );
}

function getModuleForBus(city) {
  switch (city) {
    case 'Ljubljana':
      return <LjubljanaBusModule />;
    default:
      return <NoModule>No bus module implemented for {city} yet.</NoModule>;
  }
}

export default BusContainer;
