import React from 'react';
import styled from 'styled-components';

const InOfficeContainer = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  justify-content: space-around;

  color: white;
`;

const Title = styled.h1`
  padding: 8px 10px 6px 10px;

  font-family: Sora;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  letter-spacing: -0.25px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;

  color: #05e273;
  background: #0c0c91;
`;

const InOfficeCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InOfficeList = styled.div`
  flex-grow: 1;
  max-height: 60vh;

  display: inline-grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(10, auto);
  column-gap: 2.5rem;
`;

const InOfficeItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Sora;
  font-weight: normal;
  font-size: 1.5rem;
  text-align: left;
  letter-spacing: -0.5px;
  white-space: nowrap;
  color: #0c0c91;
`;

const InOfficeItemActions = styled.div`
  margin-left: 1.5rem;
  letter-spacing: 0.5rem;
`;

const emojiMap = {
  ':coffee:': '☕️',
  ':woman-lifting-weights:': '🏋️‍♀️',
  ':hamburger:': '🍔',
  ':video_game:': '🎮',
};

const sortOrder = [':coffee:', ':hamburger:', ':video_game:', ':woman-lifting-weights:'];

function actionEmojis(personActions) {
  return sortOrder.map(action => (personActions || '').includes(action) && emojiMap[action]).filter(Boolean);
}

function shorterName(personName) {
  const parts = personName.split(/\s+/);
  // biome-ignore lint/style/useTemplate:
  return [parts[0], ...parts.slice(1).map(part => (part.length < 4 ? part : part[0] + '.'))].join(' ');
}

export const CurrentlyInOffice = ({ data = [] }) => {
  return (
    <InOfficeContainer>
      <InOfficeCategoryContainer>
        <Title>In the office today</Title>
        <InOfficeList>
          {data.map((person, index) => {
            return (
              <InOfficeItem key={`${index}${person}`}>
                <div>{shorterName(person.name)}</div>{' '}
                <InOfficeItemActions>{actionEmojis(person.actions)}</InOfficeItemActions>
              </InOfficeItem>
            );
          })}
        </InOfficeList>
      </InOfficeCategoryContainer>
    </InOfficeContainer>
  );
};
