import styled from 'styled-components';
import React from 'react';

const RssItemContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  overflow: hidden;
  color: white;
`;

const RssItemData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const RssItemText = styled.p`
  margin-bottom: 12px;

  font-family: Sora;
  text-align: start;
  font-size: 25px;
  line-height: 31px;
  color: #0c0c91;
`;

const RssItemAuthorContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  color: white;

  font-size: 20px;
  font-weight: 600;
  line-height: 24px;

  margin-bottom: 8px;
`;

const AuthorAvatar = styled.img`
  height: 40px;
  margin-right: 10px;
`;

const Time = styled.span`
  font-family: Sora;
  font-size: 16px;
  line-height: 20px;
  color: #0c0c91;
`;

export function RssItem({ author: { name = '', avatarUrl = null }, date, title }) {
  const [imageUrl, setImageUrl] = React.useState(avatarUrl);

  return (
    <RssItemContainer>
      <RssItemData>
        <RssItemAuthorContainer>
          {imageUrl ? <AuthorAvatar src={avatarUrl} onError={() => setImageUrl(null)} /> : name}
        </RssItemAuthorContainer>
        <RssItemText>{title}</RssItemText>
        {date ? <Time>{date}</Time> : null}
      </RssItemData>
    </RssItemContainer>
  );
}
