import styled from 'styled-components';

export const Mention = styled.i`
  font-weight: 500;
  display: inline-block;
  color: #05e273;
  background: #0c0c91;
`;

export const Hashtag = styled.span`
  font-style: italic;
  font-weight: 100;
  color: #e9f6f6;
  display: inline-block;
`;

export const LinkStyled = styled(Hashtag)`
  text-decoration: underline;
`;
