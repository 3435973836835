import React from 'react';
import styled from 'styled-components';

const SloganContainer = styled.div`
  position: absolute;
  bottom: 1.5%;
  left: 50%;
  margin: auto;
  z-index: 1;
  text-align: center;
  transform: translate3d(-50%, 0, 0);
  transition: all 0.35s ease-in-out;

  &.video-watermark {
    transform: scale(0.8) translate3d(195%, -21px, 0);
    svg path {
      fill: #0c0c91 !important;
    }
  }
`;

const SloganText = styled.span`
  display: block;
  padding-bottom: 38px;
  font-weight: 700;
  font-size: 20px;
  color: #04060c;
  font-family: Sora;
`

function Slogan(props) {
  return (
    <SloganContainer className={props.leetOClock ? 'video-watermark' : ''}>
      <SloganText>
        Go Beyond Ordinary.
      </SloganText>
    </SloganContainer>
  );
}

export default Slogan;
