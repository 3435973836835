import React from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
  border: none;
  margin-left: 20px;
  vertical-align: top;
  height: 50px;
  min-width: 200px;
  font-size: 16px;
  background-color: #05e273;
  color: white;
  border-radius: 3px;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: 0;
  -webkit-transition: background-color 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: background-color 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: background-color 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  font-family: 'Radikal-Light', sans-serif;
  font-weight: normal;
  font-style: normal;
  div {
    display: inline-block;
  }
  &:active,
  &:focus {
    border: 2px solid white;
  }
  ${props =>
    props.small &&
    css`
      min-width: 120px;
    `}
`;

const Button = props => {
  return (
    <StyledButton type={props.type} small={props.small} onClick={props.onClick}>
      {props.children}
    </StyledButton>
  );
};

export default Button;
