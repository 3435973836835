import React, { useState, useEffect } from 'react';
import useKeydown from '../hooks/useKeydown';
import Logo from './Logo';
import CentralContainer from './CentralContainer';
import InfoLeft from './InfoLeft';
import Video from './Video';
import Slogan from './Slogan';
import Chats from './Chats';
import LeetOClockVideo from './LeetOClockVideo';
import { connectToMqtt } from '../services/mqtt';
import { setMode, updateFilteredChats } from '../actions';
import { connect } from 'react-redux';
import { useMqttMessages } from '../hooks/useMqttMessages';
import { useDeviceShuffle } from '../hooks/useDeviceShuffle';

const mapDispatchToProps = dispatch => ({
  setMode: mode => {
    dispatch(setMode(mode));
  },
  updateFilteredChats: () => {
    dispatch(updateFilteredChats());
  },
});

function Main({ updateFilteredChats, setMode }) {
  const { processMqttMessage } = useMqttMessages();
  const { setIsShufflePlaybackPaused, setIsShufflePlaybackMuted } = useDeviceShuffle();
  const [isLeetVideoPlaying, setIsLeetVideoPlaying] = useState(false);

  useEffect(() => {
    connectToMqtt(processMqttMessage);
    // skipped in dependency array below: processMqttMessage
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsShufflePlaybackPaused(isLeetVideoPlaying);
    setIsShufflePlaybackMuted(isLeetVideoPlaying);
    // skipped in dependency array below: setIsShufflePlaybackPaused, setIsShufflePlaybackMuted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLeetVideoPlaying]);

  useEffect(() => {
    const interval = setInterval(updateFilteredChats, 10000);

    return () => clearInterval(interval);
    // skipped in dependency array below: updateFilteredChats
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handler = e => {
      switch (e.keyCode) {
        // 48 "0"
        case 49: // "1"
          setMode('events');
          break;
        case 50: // "2"
          setMode('bus');
          break;
        case 51: // "3"
          setMode('logo');
          break;
        case 52: // "4"
          setMode('clock');
          break;
        case 53: // "5"
          setMode('visualizer');
          break;
        case 54: // "6"
          setMode('photostream');
          break;
        case 55: // "7"
          setMode('videostream');
          break;
        case 56: // "8"
          break;
        case 57: // "9"
          setMode('info');
          break;
        default:
          // 76=l 78=n 80=p 82=r 83=s, 176=next 177=previous 179=play/pause, weird/special: 227= 228= 229=backspace? any button?
          if (![76, 78, 80, 82, 83, 227, 228, 229, 176, 177, 179].includes(e.keyCode)) {
            console.log('key not mapped', e.keyCode);
          }
          break;
      }
    };
    window.addEventListener('keydown', handler);

    return () => window.removeEventListener('keydown', handler);
    // skipped in dependency array below: setMode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useKeydown(() => {
    // 76=l, 229=reload (media button)
    window.location.reload();
  }, [76, 229]);

  return (
    <>
      <Video />
      <Logo leetOClock={isLeetVideoPlaying} />
      <CentralContainer />
      <InfoLeft leetOClock={isLeetVideoPlaying} />
      <Chats />
      <Slogan leetOClock={isLeetVideoPlaying} />
      <LeetOClockVideo setIsLeetVideoPlaying={setIsLeetVideoPlaying} />
    </>
  );
}

export default connect(null, mapDispatchToProps)(Main);
