export const environments = ['dev', 'staging', 'prod'];

const overriddenEnv = localStorage.getItem('env');

const env = overriddenEnv || process.env.REACT_APP_DEPLOY_ENV;

const options = {
  awsIoTEndpoint: 'a1qxyuk28vn8w4-ats.iot.eu-central-1.amazonaws.com',
  awsRegion: 'eu-central-1',
  fallbackVideoArchive: 'http://klemen-leet-css-video-demo.s3-website.eu-central-1.amazonaws.com/',
  NOTIFY_CLIENTS_INTERVAL: 5,
  weatherAppId: '2207c1cd456c9e28b72bd67e5ffe3c42',
};

if (!env || env === 'local') {
  Object.assign(options, {
    stage: 'dev',
    awsIoTEndpoint: 'a1qxyuk28vn8w4-ats.iot.eu-central-1.amazonaws.com',
    host: 'http://localhost:3000',
    basePath: '/tv',
    s3Endpoint: 'https://dev-infoboard.s3.amazonaws.com',
  });
} else if (env === 'offline') {
  Object.assign(options, {
    stage: 'prod',
    host: 'https://api.tv.tretton37.com',
    bareHost: 'api.tv.tretton37.com',
    basePath: '/prod/tv',
    s3Endpoint: 'http://localhost:1337',
  });
} else if (env === 'staging') {
  Object.assign(options, {
    stage: 'staging',
    awsIoTEndpoint: 'a1qxyuk28vn8w4-ats.iot.eu-central-1.amazonaws.com',
    host: 'https://api.tv.tretton37.com',
    bareHost: 'api.tv.tretton37.com',
    basePath: '/staging/tv',
    s3Endpoint: 'https://staging-infoboard.s3.amazonaws.com',
  });
} else if (env === 'production') {
  Object.assign(options, {
    stage: 'prod',
    host: 'https://api.tv.tretton37.com',
    bareHost: 'api.tv.tretton37.com',
    basePath: '/prod/tv',
    s3Endpoint: 'https://prod-infoboard.s3.amazonaws.com',
  });
}

export default options;
