import * as actionTypes from '../constants/actionTypes';

const bus = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_BUS_INFO:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default bus;
