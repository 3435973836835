import { disconnectMQTTClient } from '../services/mqtt';

export function parseGraphQLApiErrors(errors) {
  if (!errors) {
    return [];
  }

  if (errors.fetchError) {
    return [{ errorMessage: errors.fetchError.message }];
  }
  if (errors.httpError) {
    return [{ errorMessage: errors.httpError.body }];
  }
  if (errors.graphQLErrors) {
    return errors.graphQLErrors.map(error => {
      return { errorMessage: error.message, fieldErrors: parseFieldErrors(error) };
    });
  }
}

function parseFieldErrors(error) {
  if (error && error.extensions && error.extensions.fieldErrors) {
    return error.extensions.fieldErrors;
  }
  return {};
}

export function disconnectAndRefresh() {
  localStorage.clear();
  disconnectMQTTClient();
  setTimeout(() => window.location.reload(true), 2000);
}
