import { useEffect } from 'react';

function useKeydown(callback, keys) {
  useEffect(() => {
    const handler = e => (keys.indexOf(e.keyCode) >= 0 ? callback(e.keyCode) : null);
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
    // ignored dependency: callback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys]);
}

export default useKeydown;
