import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';

const TimeInfo = styled.span`
  font-size: 24vw;
  line-height: 100%;
  margin: 0px auto;
  font-family: Sora, sans-serif;
  color: white;
`;

const TimeContainer = styled(
  posed.div({
    open: { left: 0 },
    closed: { left: '-200%' },
  })
)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  top: 0px;
  bottom: 0px;
  position: absolute;
`;

function formatTime(d, isOpened) {
  const hours = d
    .getHours()
    .toString()
    .padStart(2, '0');
  const minutes = d
    .getMinutes()
    .toString()
    .padStart(2, '0');
  return (
    <TimeContainer pose={isOpened ? 'open' : 'closed'}>
      <TimeInfo>
        {hours}:{minutes}
      </TimeInfo>
    </TimeContainer>
  );
}

function Clock({ isOpened }) {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const tick = () => setTime(new Date());
    const interval = setInterval(tick, 1 * 1000);
    return () => clearInterval(interval);
  }, [setTime]);

  return formatTime(time, isOpened);
}

export default Clock;
