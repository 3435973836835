import React from 'react';
import styled from 'styled-components';

import { useFeed } from '../hooks/useFeed';
import useKeydown from '../hooks/useKeydown';
import { getContentComponent } from '../helpers/contentFeed';
import { fadeInAndOut } from '../utils/animations';

const StyledContainer = styled.div`
  height: 100vh;
  width: 100%;

  transition: opacity 1s;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
`;
const StyledContent = styled.div`
  height: 100%;
  width: 84vw;

  display: flex;
  align-items: center;
  margin: 0 auto;

  animation: ${fadeInAndOut} 20s ease-in-out 1;
`;
const actionTypes = {
  setItems: 'setItems',
  tick: 'tick',
};
const initialState = {
  currentIndex: 0,
  feedItems: [],
};

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.setItems:
      const { feedItems } = action;
      const activeItem = feedItems[state.currentIndex];
      const activeItemIndex = activeItem
        ? feedItems.findIndex(feedItem => feedItem.type === activeItem.type && feedItem.id === activeItem.id)
        : -1;

      return {
        ...state,
        feedItems,
        currentIndex: activeItemIndex === -1 ? 0 : activeItemIndex,
      };
    case actionTypes.tick:
      return { ...state, currentIndex: (state.currentIndex + 1) % state.feedItems.length };
    default:
      return state;
  }
}

export function Feed({ handleKeyPress, isOpened }) {
  const slides = useFeed();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { currentIndex } = state;
  const activeItem = slides[currentIndex] || { type: null, id: '', data: {} };

  const Component = getContentComponent(activeItem.type);

  useKeydown(() => {
    // 78=n
    handleKeyPress && dispatch({ type: actionTypes.tick });
  }, [78]);

  React.useEffect(() => {
    if (slides.length) {
      dispatch({ type: actionTypes.setItems, feedItems: slides });
    }
  }, [slides]);

  return (
    <StyledContainer isOpen={isOpened}>
      <StyledContent
        key={activeItem.id}
        onAnimationEnd={event => {
          // The screens may have their own animations.
          // TODO: Find a better way to ensure we trigger on our own animation
          if (event.elapsedTime > 10) dispatch({ type: actionTypes.tick });
        }}
      >
        <Component {...activeItem} />
      </StyledContent>
    </StyledContainer>
  );
}
