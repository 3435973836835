import React from 'react';
import styled from 'styled-components';
import { FiMusic, FiSkipForward } from 'react-icons/fi';
import { decodeString } from '../helpers/stringDecoder';

const InfoRight = styled.div`
  position: absolute;
  text-align: right;
  transform: translate3d(120%, 0, 0);
  opacity: 0;
  bottom: 2%;
  right: 2%;
  min-height: 38px;
  color: white;
  font-size: 14px;
  z-index: 1;
  font-weight: 100;
  white-space: nowrap;
  transition: opacity 0.25s ease-in-out;
  animation: sectionFlyIn 0.25s;
  animation-delay: 1.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  color: #0c0c91;

  &.animate-out {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    animation: sectionFlyOutRight 0.45s;
    animation-delay: 0s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  svg {
    margin-top: -1px;
  }
`;
const SongItem = styled.div`
  transform-origin: right center;

  > svg {
    position: absolute;
    left: -18px;
    top: 4px;
  }

  &:nth-child(1) {
    > svg {
      &:nth-child(2) {
        opacity: 0;
      }
    }
  }

  &:nth-child(2),
  &:nth-child(3) {
    color: #0c0c91;
    transform: translate3d(0, 0, 0) scale(0.75);
    position: absolute;
    opacity: 0.5;
    right: 0;
    top: 16px;
    > svg {
      &:nth-child(1) {
        opacity: 0;
        transform: translate3d(0, -14px, 0);
      }
    }
  }
  &:nth-child(3) {
    transform: translate3d(100%, 0, 0) scale(0.75);
    -webkit-font-smoothing: subpixel-antialiased;
    backface-visibility: hidden;
    opacity: 0;
  }

  .switchSong & {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

    > svg {
      transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
      transition-delay: 0.2s;
    }

    &:nth-child(1) {
      transform: translate3d(120%, 0, 0);
      opacity: 0;
    }
    &:nth-child(2) {
      transform: translate3d(0, -16px, 0) scale(1);
      opacity: 1;
      > svg {
        &:nth-child(1) {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
        &:nth-child(2) {
          opacity: 0;
          transform: translate3d(0, 14px, 0);
        }
      }
    }
    &:nth-child(3) {
      transform: translate3d(0, 0, 0) scale(0.75);
      opacity: 0.5;
    }
  }
`;

const SongName = styled.span`
  max-width: 30vw;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function Songs({ songs }) {
  return songs.map((track, index) => {
    if (!track) {
      return null;
    }
    return (
      <SongItem key={index}>
        <FiMusic />
        <FiSkipForward />{' '}
        <SongName>
          {decodeString(track.title)}
          {track.artist ? ` - ${decodeString(track.artist)}` : null}
        </SongName>
      </SongItem>
    );
  });
}

const VideoInfo = props => (
  <InfoRight {...props} className={`${props.switchSong ? 'switchSong' : ''} ${props.isHidden ? 'animate-out' : ''}`}>
    <Songs songs={[props.playing, props.next]} />
  </InfoRight>
);

export default VideoInfo;
