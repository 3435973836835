import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import posed from 'react-pose';
import { ReactComponent as Tretton37LogoUnstyled } from '../assets/tretton37_blue.svg';

const WhitePath = styled.path`
  fill: white;
`;

const WhitePoly = styled.polygon`
  fill: white;
`;

const FullLogo = styled.svg`
  width: 200px;
  & > g > g > path,
  .logo svg.full > g > g > polygon {
    transition: opacity 0.3s ease-in-out, transform 0.7s ease-in-out;
  }

  & > g > g > polygon:nth-child(5) {
    transform-origin: center;
  }
  .change-trans &,
  .front-logo & {
    & > g > g > polygon:nth-child(3) {
      transform: scale(0.4) translate3d(253px, 78px, 0);
    }
    & > g > g > polygon:nth-child(5) {
      transform: scale(-0.5, 0.5) translate3d(279px, -14px, 0);
    }
    & > g > g > path:nth-child(6) {
      transform: scale(0.4) translate3d(419px, 79px, 0);
    }
    & > g > g > path:nth-child(7) {
      transform: scale(0.4) translate3d(230px, 47px, 0);
    }
    & > g > g > path:nth-child(1),
    & > g > g > path:nth-child(2),
    & > g > g > path:nth-child(4),
    & > g > g > path:nth-child(8),
    & > g > g > path:nth-child(9) {
      opacity: 0;
      transform: translate3d(0, 40%, 0);
    }
  }
`;
function Full(props) {
  return (
    <FullLogo className="full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 456.82 89.62" change={props.change}>
      <title>_tretton37 logo white</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <WhitePath d="M16.19,74.86a27.32,27.32,0,0,1-6.86-.8,11.4,11.4,0,0,1-4.89-2.64,12.94,12.94,0,0,1-3.23-5A24,24,0,0,1,0,58.1V0H14V14.76H28.47V27H14V55.33c0,5,2.61,7.72,7.35,7.72a21,21,0,0,0,6.89-1V72.28A33.94,33.94,0,0,1,23.39,74,26,26,0,0,1,16.19,74.86Z" />
          <WhitePath d="M240.23,74.87c-12.88,0-25-7.41-29.46-19.72a30.1,30.1,0,0,1,9.83-33.82A33.44,33.44,0,0,1,256.84,19,30,30,0,0,1,271,51c-2.61,12.48-13.44,21.82-25.92,23.53A36.25,36.25,0,0,1,240.23,74.87Zm0-49.06a17.38,17.38,0,0,0-16.64,12.45c-2.25,7.29-.4,15.74,5.47,20.79a18,18,0,0,0,20.72,1.7c6.55-4,9.18-12.18,7.94-19.53C256.27,32.68,249,25.81,240.23,25.81Z" />
          <WhitePoly points="402.4 14.76 402.4 27.02 441.25 27.02 406.7 89.62 422.68 89.62 456.82 25.82 456.82 14.76 402.4 14.76" />
          <WhitePath d="M305,14.76H278.67v60.1h14V27h12.77a11,11,0,0,1,8.07,3.1c3.89,4.11,3.89,13.42,3.89,20.69V74.86h14V41.12C331.4,18.18,314.88,14.76,305,14.76Z" />
          <WhitePoly points="54.14 14.76 38.47 14.76 38.47 74.86 52.47 74.86 52.47 27.01 67.05 27.01 54.14 14.76" />
          <WhitePath d="M119.4,68.2a32.45,32.45,0,0,1-7.92,6.23,23.94,23.94,0,0,1-11.57,2.68,14.17,14.17,0,0,1-9.32-3,10.43,10.43,0,0,1-3.78-8.41v-.23a10.09,10.09,0,0,1,4.94-9c3-1.92,7.24-2.9,12.53-2.9h2.38l2.19-8.92L91.33,27h33.72V14.76h-51v11L93.22,44l-.15,0a39.83,39.83,0,0,0-7.66,2.09A21.81,21.81,0,0,0,79,49.93a17.82,17.82,0,0,0-4.48,6,21,21,0,0,0-1.68,8.86v.23a25,25,0,0,0,1.88,9.83,23,23,0,0,0,5.38,7.75,24.5,24.5,0,0,0,8.57,5.14,34.1,34.1,0,0,0,11.49,1.85c7,0,12.94-1.24,17.76-3.69a38.59,38.59,0,0,0,11.21-8.47Z" />
          <WhitePath d="M347.05,68.2A32.68,32.68,0,0,0,355,74.43a23.87,23.87,0,0,0,11.56,2.68,14.15,14.15,0,0,0,9.32-3,10.43,10.43,0,0,0,3.78-8.41v-.23a10.1,10.1,0,0,0-4.93-9c-3-1.92-7.25-2.9-12.53-2.9h-2.39l-2.19-8.92L375.13,27H341.4V14.76h51v11L373.23,44l.15,0a40.13,40.13,0,0,1,7.67,2.09,22.07,22.07,0,0,1,6.43,3.83A17.8,17.8,0,0,1,392,56a21,21,0,0,1,1.69,8.86v.23a25,25,0,0,1-1.89,9.83,23,23,0,0,1-5.38,7.75,24.5,24.5,0,0,1-8.57,5.14,34.06,34.06,0,0,1-11.49,1.85c-7,0-12.94-1.24-17.76-3.69a38.42,38.42,0,0,1-11.2-8.47Z" />
          <WhitePath d="M151.28,74.88a26.84,26.84,0,0,1-6.86-.81,11.28,11.28,0,0,1-4.89-2.63,13.09,13.09,0,0,1-3.23-5,24,24,0,0,1-1.21-8.29V0h14V14.77h14.47V27H149.09V55.34c0,5,2.61,7.72,7.35,7.72a21,21,0,0,0,6.89-1V72.29A31.64,31.64,0,0,1,158.48,74,25.56,25.56,0,0,1,151.28,74.88Z" />
          <WhitePath d="M189.74,74.88a26.76,26.76,0,0,1-6.85-.81A11.22,11.22,0,0,1,178,71.44a13,13,0,0,1-3.23-5,23.81,23.81,0,0,1-1.21-8.29V0h14V14.77H202V27H187.55V55.34c0,5,2.62,7.72,7.36,7.72a20.88,20.88,0,0,0,6.88-1V72.29A31.52,31.52,0,0,1,197,74,25.63,25.63,0,0,1,189.74,74.88Z" />
        </g>
      </g>
    </FullLogo>
  );
}

const ShorthandLogo = styled.svg`
  width: 70px;
  & > g > g > path,
  & > g > g > polygon {
    transition: opacity 0.3s ease-in-out;
  }

  .change-trans &,
  .front-logo & {
    & > g > g > path,
    & > g > g > polygon {
      opacity: 1;
      transition-delay: 0.5s;
    }
  }
  .logo:not(.change-trans):not(.front-logo) & {
    & > g > g > path,
    & > g > g > polygon {
      opacity: 0;
      transition-delay: 0.1s;
    }
  }
`;

function Shorthand(props) {
  return (
    <ShorthandLogo
      change={props.change}
      className="shorthand"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 154.01 89.35"
    >
      <title>Asset 8</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <WhitePath d="M0,0V89.35H154V0ZM148.89,84.46H5.12V4.89H148.89Z" />
        </g>
      </g>
    </ShorthandLogo>
  );
}

const LogoContainer = styled(
  posed.div({
    expanded: {
      scale: 4,
      top: '32vh',
    },
    small: {
      scale: 1,
      top: 30,
    },
  })
)`
  position: relative;
  top: 30px;
  & svg {
    position: absolute;
    left: 0;
    top: 4%;
    transform: translate3d(-50%, 0, 0);
    left: 50%;
    transition: transform 0.3s ease-in-out;
  }
  &.front-logo {
    transform: scale(0.7) translate3d(0px, 18px, 0);
    z-index: 30;
    position: relative;
    svg {
      z-index: 3;

      &:nth-child(2) {
        z-index: 2;
        background-color: rgb(0, 43, 85);
      }
    }
  }
  &.full-screen {
    transform: scale(4);
    margin-top: 32vh;
  }
  &.onRegister {
    top: -100px !important;
  }
`;

function mapStateToProps(state) {
  return {
    mode: state.mode,
  };
}

// TODO click handler to start video playback
const Logo = props => {
  // Todo call right away :/ setChange(true) on leetOClock change
  const [change, setChange] = useState(false);
  const changeMode = () => (props.leetOClock ? true : setChange(!change));

  useEffect(() => {
    const INTERVAL_SECONDS = 20;
    const interval = setInterval(changeMode, INTERVAL_SECONDS * 1000);
    return () => clearInterval(interval);
    // skipped in dependency array below: changeMode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change]);

  return (
    <>
      <LogoContainer
        pose={props.mode === 'logo' && !props.onRegister ? 'expanded' : 'small'}
        className={`logo ${props.leetOClock ? 'front-logo' : ''} ${change ? 'change-trans' : ''} ${
          props.onRegister ? 'onRegister' : ''
        }`}
      >
        <Full />
        <Shorthand />
      </LogoContainer>
    </>
  );
};

export const _blank = connect(mapStateToProps, undefined)(Logo);

const Tretton37Logo = styled(Tretton37LogoUnstyled)`
  position: fixed;
  top: 40px;
  left: 50%;
  transform: translateX(-50%) scale(1.7);
  width: 88px;
`;
export default function TemporaryNewLogo() {
  return (
    <div>
      <Tretton37Logo />
    </div>
  );
}
