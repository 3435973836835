import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useAuthentication } from '../hooks/useAuthentication';
import useKeydown from '../hooks/useKeydown';

import { start, stop, destroyRenderer } from '../utils/WebGL';
import {
  startMusicVisualization,
  stopMusicVisualization,
  destroyMusicVisualization,
} from '../utils/audioVisualization';
import config from '../config';

const photosFromStream = [
  'https://live.staticflickr.com/2937/14252780370_91764ddf60_o.jpg',
  'https://live.staticflickr.com/3498/13035596294_abbcd1fe25_o.jpg',
  'https://live.staticflickr.com/3712/10314077315_22ef71d7be_o.jpg',
  'https://live.staticflickr.com/3838/14252929127_810afe804f_o.jpg',
  'https://live.staticflickr.com/3913/14906339147_796698e893_o.jpg',
  'https://live.staticflickr.com/4678/39622065425_917630f849_o.jpg',
  'https://live.staticflickr.com/4764/39622060895_de8ec5d7b4_o.jpg',
  'https://live.staticflickr.com/4770/38707061570_963714747c_o.jpg',
  'https://live.staticflickr.com/5487/10314193653_5c263a5070_o.jpg',
  'https://live.staticflickr.com/5586/15069896946_77748f4a5a_o.jpg',
  'https://live.staticflickr.com/660/22090030988_f0ed16217a_o.jpg',
  'https://live.staticflickr.com/7207/13604836403_08058aa264_o.jpg',
  'https://live.staticflickr.com/7338/16257739568_08ab0d4b98_o.jpg',
  'https://live.staticflickr.com/7352/16257970430_2db7dae831_o.jpg',
  'https://live.staticflickr.com/7354/8724415943_0d9542f663_o.jpg',
  'https://live.staticflickr.com/7390/8724415133_3995bdc18e_o.jpg',
  'https://live.staticflickr.com/8329/8120017099_d3dc007b74_o.jpg',
  'https://live.staticflickr.com/8343/29650892815_104411dc55_o.jpg',
  'https://live.staticflickr.com/8466/8120016780_89f4bb09c7_o.jpg',
  'https://live.staticflickr.com/8473/8419720760_f68645f2bf_o.jpg',
  'https://live.staticflickr.com/8531/8486935872_75aec0b000_o.jpg',
];

function getRandomPhotoFromStream() {
  return photosFromStream[Math.floor(photosFromStream.length * Math.random())];
}

const Canvas = styled.canvas`
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

const Crest = styled.img`
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  margin-left: -40px;
  top: 50%;
  margin-top: -40px;
  transition: scale 0.1s;
  opacity: ${({ show }) => (show ? 1 : 0)};
  ${props =>
    props.scale &&
    css`
      transform: scale(${1 + props.scale / 280});
    `}
`;

export default function VideoPlayer(props) {
  const { showVideo = true } = props;
  const { deviceId } = useAuthentication();
  const [videoElement] = useState(document.createElement('video'));
  const imageRef = React.useRef(null);
  const [bassLevel, setBassLevel] = useState(100);
  videoElement.crossOrigin = 'anonymous';

  useEffect(() => {
    return () => {
      videoElement.removeEventListener('playing', handlePlayingEvent, true);
      destroyRenderer();
      destroyMusicVisualization();
    };
    // ignores: handlePlayingEvent, videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handlePlayingEvent() {
    if (props.mode === 'visualizer') {
      stop();
      startMusicVisualization(videoElement, setBassLevel);
    } else {
      stopMusicVisualization();
      start(videoElement, showVideo);
    }
  }

  // Load and play video
  useEffect(() => {
    const srcBase = deviceId === '761b3c20-5891-11ea-8b2f-9b3d847a1596' ? 'http://localhost:3000' : config.s3Endpoint;
    videoElement.src = `${srcBase}/videos/${props.video.id}.mp4`;
    videoElement.load();
    videoElement.addEventListener('playing', handlePlayingEvent, true);

    videoElement.play().catch(err => {
      console.error('.play errored :(' + [err.name, err.message].join(' '));
      // This is getting called when there is no interaction with document
      // For our use case this isn't exactly an error since android app
      // allows media elements to play even if there is no interaction
      // TL;DR We can safely ignore the error
    });

    return () => videoElement.pause();
    // ignores: handlePlayingEvent, videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.video.id, deviceId]);

  const sendCurrentStatus = () => {
    props.notifyClients({
      source: 'tv',
      action: 'update',
      resource: 'playing',
      resourceIdentifier: 'info',
      object: {
        ...props.video,
        duration: videoElement.duration,
        isVideoPlaying: !videoElement.paused,
        currentTime: videoElement.currentTime,
      },
    });
  };

  useEffect(() => {
    if (props.mode === 'visualizer') {
      startMusicVisualization(videoElement, setBassLevel);
    } else {
      stopMusicVisualization(videoElement);
    }
    if (showVideo) {
      stopMusicVisualization(videoElement);
      start(videoElement, showVideo);
    } else {
      stop();
    }
    // ignores: videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode, showVideo]);

  useEffect(() => {
    if (videoElement && props.playingState) {
      const interval = setInterval(
        () => sendCurrentStatus(),
        (showVideo ? 1 : 2) * config.NOTIFY_CLIENTS_INTERVAL * 1000
      );
      sendCurrentStatus();
      return () => clearInterval(interval);
    }
    // ignores: sendCurrentStatus, videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.playingState, props.video.id]);

  useEffect(() => {
    if (videoElement) {
      videoElement.addEventListener('ended', props.handleNextSong);
      return () => {
        videoElement.removeEventListener('ended', props.handleNextSong);
      };
    }
    // ignores: props.handleNextSong, videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.video.id]);

  useKeydown(() => {
    if (videoElement) {
      videoElement.currentTime = 0;
    }
  }, [82, 177]); // R or ± = restart video from 0

  useEffect(() => {
    videoElement.muted = props.muted;
    // ignores: videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.muted]);

  useEffect(() => {
    setTimeout(() => {
      if (videoElement && props.playingState) {
        videoElement.play().catch(console.error);
      } else if (videoElement) {
        videoElement.pause();
      }
      sendCurrentStatus();
    }, 0);
    // ignores: sendCurrentStatus, videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.playingState]);

  const togglePlay = notifyClientsCallback => {
    if (videoElement && videoElement.paused) {
      videoElement.play();
    } else if (videoElement) {
      videoElement.pause();
    }
    notifyClientsCallback();
  };
  useKeydown(() => {
    togglePlay(() => {});
  }, [80, 179]); // P or ³ = toggle Pause / Play

  useEffect(() => {
    const interval = setInterval(() => {
      if (!showVideo) {
        imageRef.current.src = getRandomPhotoFromStream();
      }
    }, config.NOTIFY_CLIENTS_INTERVAL * 1000);
    return () => clearInterval(interval);
  }, [showVideo]);

  return (
    <>
      <Canvas show={showVideo} id="glCanvas" width="640" height="360" />
      <Canvas background show={props.mode === 'visualizer'} id="audioCanvas" width="100%" height="100%" />
      <ImageBackground imageRef={imageRef} showVideo={showVideo} />
      <Crest src="/_tretton37_crest_green.png" scale={bassLevel} show={props.mode === 'visualizer'} />
    </>
  );
}

const BleachEffect = styled.div`
  position: absolute;
  width: 100%;
  height: auto;

  & img {
    display: block;
    width: 100%;
    height: auto;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

function ImageBackground({ showVideo, imageRef }) {
  return (
    <BleachEffect>
      <img
        ref={imageRef}
        width={showVideo ? '0px' : '100vw'}
        height={showVideo ? '0px' : '100vh'}
        alt=""
        src="/_tretton37_crest_green.png"
        style={
          showVideo
            ? { display: 'none' }
            : {
                width: '100vw',
                height: '100vh',
                paddingBottom: '100%',
                objectFit: 'cover',
                opacity: 0.1,
              }
        }
      />
    </BleachEffect>
  );
}
