import React from 'react';
import styled from 'styled-components';
import { useWeather } from '../hooks/useWeather';

const WeatherInfo = styled.div`
  position: fixed;
  font-size: 45px;
  top: 19px;
  right: 40px;
`;

const WeatherIcon = styled.img`
  float: left;
  filter: grayscale(1);
  margin-top: 5px;
  margin-right: 8px;
`;

function MiniWeather({ show }) {
  const { weather } = useWeather();

  return show && weather.min && Number.isFinite(weather.min) ? (
    <WeatherInfo>
      <WeatherIcon src={weather.icon} />

      {`${Math.round(weather.temp)}°C`}
    </WeatherInfo>
  ) : (
    <></>
  );
}

export default MiniWeather;
