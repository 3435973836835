import { Tweet } from '../components/Tweet';
import { RssItem } from '../components/RssItem';
import { CurrentlyInOffice } from '../components/CurrentlyInOffice';
import { KnowabungaCountdown } from '../components/KnowabungaCountdown';

const KB_TYPE = 'knowabungaCountdown';
const IN_OFFICE_TYPE = 'currentlyInOffice';

const contentMap = {
  tweet: Tweet,
  rssItem: RssItem,
  [IN_OFFICE_TYPE]: CurrentlyInOffice,
  [KB_TYPE]: KnowabungaCountdown,
};

export const getContentComponent = type => contentMap[type] || 'div';

export function preparePeopleInOfficeSlide(data = [], index = 0) {
  return {
    id: `${IN_OFFICE_TYPE}-${index}`,
    type: IN_OFFICE_TYPE,
    data: (data || []).map(({ name, actions }) => ({ name, actions })),
  };
}

export function prepareCountdown(index = 0) {
  return {
    id: `${KB_TYPE}-${index}`,
    type: KB_TYPE,
  };
}
