import React from 'react';
import styled from 'styled-components';

import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import StationInfo from './LjubljanaStationInfo';

const Arrows = styled.span`
  color: #05e273;
  svg {
    margin-bottom: -3px;
  }
`;

function Down() {
  return (
    <Arrows>
      <FiArrowDown />
      <FiArrowDown />
    </Arrows>
  );
}

function Up() {
  return (
    <Arrows>
      <FiArrowUp />
      <FiArrowUp />
    </Arrows>
  );
}

function BusInfo(props) {
  return (
    <>
      <StationInfo title="Pošta" stationId="601011" arrows={<Down />} distance="100" />
      <StationInfo title="Konzorcij" stationId="601012" arrows={<Up />} distance="20" />
    </>
  );
}

export default BusInfo;
