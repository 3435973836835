import * as actionTypes from '../constants/actionTypes';

export function fetchLjubjanaBusInformationForStation(stationId) {
  return {
    type: actionTypes.FETCH_LJUBLJANA_BUS_INFO,
    stationId,
  };
}

export function setMode(mode) {
  return {
    type: actionTypes.SET_MODE,
    mode,
  };
}

export function reloadApp() {
  return {
    type: actionTypes.RELOAD_APP,
  };
}

export function switchEnvironment(env) {
  return {
    type: actionTypes.SWITCH_ENVIRONMENT,
    env,
  };
}

export function updateFilteredChats() {
  return {
    type: actionTypes.UPDATE_FILTERED_CHATS,
  };
}
