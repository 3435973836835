let frequency_array;
let analyser;
let ctx;

// the sum of these two must be a power of 2
let base_bars = 16;
let shown_bars = 48;

const bar_width = 2;
// spread frequency animation bars equally around the crest
const rads = Math.PI / shown_bars; // right side (1 π = half circle)
const rads_offset = -Math.PI / 2; // rotate back "3 hours" to begin up top, instead of out right

let init = false;
let animationLoopFrame;
let setBassFunction;

export function startMusicVisualization(videoElement, setBassLevel) {
  if (!init) {
    let context = new (window.AudioContext || window.webkitAudioContext)();
    analyser = context.createAnalyser();
    let source = context.createMediaElementSource(videoElement);
    source.connect(analyser);
    analyser.connect(context.destination);
    // must be power of 2. And freq bincount will be half of this number
    // NOTE: if context.sampleRate is far different from 44k1 or 48k, we might miss half the spectrum
    analyser.fftSize = 4 * (base_bars + shown_bars);
    frequency_array = new Uint8Array(analyser.frequencyBinCount);
    setBassFunction = setBassLevel;
    init = true;
  }
  animationLooper();
}

export function stopMusicVisualization() {
  if (animationLoopFrame) {
    window.cancelAnimationFrame(animationLoopFrame);
  }
}

export function destroyMusicVisualization() {
  stopMusicVisualization();
  init = false;

  // Reset to initial state once animationLoopFrame animation frame is canceled to prevent errors of
  // type "Can't read property X of null."
  window.requestAnimationFrame(() => {
    animationLoopFrame = null;
    setBassFunction = null;
    frequency_array = null;
    analyser = null;
    ctx = null;
  });
}

function animationLooper() {
  // set to the size of device
  let canvas = document.getElementById('audioCanvas');
  canvas && audioAnimationLooper(canvas);
  animationLoopFrame = window.requestAnimationFrame(animationLooper);
}

function audioAnimationLooper(canvas) {
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  ctx = canvas.getContext('2d');

  // find the center of the window
  const mid_x = canvas.width / 2;
  const mid_y = canvas.height / 2;
  const radius = 50;

  //draw a circle
  ctx.beginPath();
  ctx.strokeStyle = '#0c0c91';
  ctx.arc(mid_x, mid_y, radius, 0, 2 * Math.PI);
  ctx.stroke();

  // skip the lower base (with typically strong peaks), and we'll instead use these to animate the crest.
  let slice_from = base_bars; // begin at i * 3, i.e. 30

  analyser.getByteFrequencyData(frequency_array);

  // We pick <= shown_bars to also get bars "at 6 o clock"
  for (var i = 0; i <= shown_bars; i++) {
    let intensity = frequency_array[slice_from++];
    let bar_height = intensity * 0.7; // 70%

    // set coordinates
    let x = Math.cos(rads * i + rads_offset) * radius;
    let y = Math.sin(rads * i + rads_offset) * radius;
    let x_end = Math.cos(rads * i + rads_offset) * (radius + bar_height);
    let y_end = Math.sin(rads * i + rads_offset) * (radius + bar_height);

    // draw right side bar
    drawBar(mid_x + x, mid_y + y, mid_x + x_end, mid_y + y_end, intensity);
    // draw left side (= flipped right side)
    drawBar(mid_x - x, mid_y + y, mid_x - x_end, mid_y + y_end, intensity);
    setBassFunction(Math.max(0, frequency_array[3] - 200));
  }
}

// for drawing a bar
function drawBar(x1, y1, x2, y2, strength) {
  // color is linear in peak strength, [0,255]
  if (strength < 0 || strength > 256) {
    new Error({ message: 'drawBar got wrong bar intensity number' });
  }
  // ranging from (0,43,85) to (127,>255,101)
  var r = strength >> 1;
  var g = 43 + Math.floor((strength * 154) / 246);
  if (g > 255) g = 255;
  var b = (85 + strength) >> 3;
  var lineColor = `rgb(${r},${g},${b})`;

  ctx.strokeStyle = lineColor;
  ctx.lineWidth = bar_width;
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.stroke();
}
