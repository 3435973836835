import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import useKeydown from '../hooks/useKeydown';
import { useAuthentication } from '../hooks/useAuthentication';
import { useDeviceShuffle } from '../hooks/useDeviceShuffle';
import { publishToMQTT } from '../services/mqtt';

import VideoInfo from './VideoInfo';
import VideoPlayer from './VideoPlayer';

function mapStateToProps(state) {
  return {
    mode: state.mode,
  };
}

function Video(props) {
  const [fade, setFade] = useState(false);
  const {
    currentTrack,
    nextTrack,
    isShufflePlaybackPaused,
    isShufflePlaybackMuted,
    setIsShufflePlaybackPaused,
    playNextTrack,
  } = useDeviceShuffle();
  const { deviceId } = useAuthentication();

  useEffect(() => {
    if (currentTrack) {
      setIsShufflePlaybackPaused(false);
    }
    // skipped in dependency array below: setIsShufflePlaybackPaused
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrack]);

  useEffect(() => {
    setFade(true);
    const timeout = setTimeout(() => setFade(false), 1500);

    return () => clearTimeout(timeout);
  }, [isShufflePlaybackPaused]);

  const handleNextSong = () => {
    setFade(true);
    setTimeout(() => playNextTrack(), 1500);
    setTimeout(() => setFade(false), 1500);
  };

  useKeydown(() => {
    handleNextSong();
    // 83=s, 176=next (media button), 228=?
  }, [83, 228, 176]);

  const notifyClients = event => {
    publishToMQTT(`device/${deviceId}`, event);
  };

  if (!currentTrack) {
    return null;
  }

  return (
    <>
      <VideoPlayer
        fade={fade}
        muted={isShufflePlaybackMuted}
        handleNextSong={handleNextSong}
        playingState={!isShufflePlaybackPaused}
        notifyClients={notifyClients}
        mode={props.mode}
        video={currentTrack}
        showVideo={props.mode === 'videostream'}
      />

      {currentTrack ? (
        <VideoInfo
          isHidden={isShufflePlaybackPaused || isShufflePlaybackMuted}
          onClick={handleNextSong}
          switchSong={props.fade}
          playing={currentTrack}
          next={nextTrack}
        />
      ) : null}
    </>
  );
}

export default connect(mapStateToProps)(Video);
