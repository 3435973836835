import styled from 'styled-components';
import React from 'react';

import { Hashtag, LinkStyled, Mention } from '../components/common/FeedContent';
import { ReactComponent as TwitterLogo } from '../assets/icon-twitter.svg';

const TRETTON37 = 'tretton37';
const linkRegex = /https?:\/\/[^\s]+/g;
const highlightsRegex = /(#[^\s]+|@[^\s]+|https?:\/\/[^\s]+)/g;
const mapTweet = tweet =>
  decodeHTML(tweet)
    .split(highlightsRegex)
    .map((s, idx) => {
      if (linkRegex.test(s)) {
        return <LinkStyled key={`link${s}${idx}`}>{s.replace(/https?:\/\/+/g, '')}</LinkStyled>;
      }

      switch (s.charAt(0)) {
        case '@':
          return <Mention key={`mention${s}${idx}`}>{s}</Mention>;
        case '#':
          return <Hashtag key={`hashtag${s}${idx}`}>{s}</Hashtag>;
        default:
          return <span key={`string${s}${idx}`}>{s}</span>;
      }
    });

const cleanText = tweet => {
  return decodeHTML(tweet)
    .split(linkRegex)
    .filter(s => !linkRegex.test(s))
    .map((s, idx) => {
      return <span key={`string${s}${idx}`}>{s}</span>;
    });
};

const TwitterLogoStyled = styled(({ isTrettonTweet, ...props }) => <TwitterLogo {...props} />)`
  color: ${({ isTrettonTweet }) => (isTrettonTweet ? '#05e273' : 'white')};
  margin-right: 8px;
`;

const TweetContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TweetData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  overflow: hidden;
  color: white;
`;

const TweetText = styled.p`
  margin-bottom: 12px;

  font-family: Sora;
  text-align: start;
  font-size: 25px;
  line-height: 31px;
  color: #0c0c91;
`;

const NewsText = styled.p`
  margin: 12px 0;

  font-family: Merriweather;
  text-align: start;
  font-size: 24px;
  line-height: 38px;
`;

const AuthorContainer = styled.div`
  display: flex;
  align-items: center;

  transition: transform 0.25s ease-in-out;
  transform: translate3d(0, 0, 0);
`;

const NewsAuthor = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

const TweetAuthorContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  color: white;

  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
`;

const TweetAuthorSpan = styled.span`
  color: ${({ isTrettonTweet }) => (isTrettonTweet ? '#05e273' : '#0c0c91')};
`;

const AuthorAvatar = styled.img`
  width: 30px;
  height: 30px;

  margin-right: 10px;

  border-radius: 50%;
  border: 1px solid white;
`;

const Time = styled.span`
  font-family: Sora;
  font-size: 16px;
  line-height: 20px;
`;

const ImageContainer = styled.div`
  position: absolute;
  width: 50%;
  max-height: 50vh;
  left: -16%;

  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;

  border-radius: 10px;
  overflow: hidden;

  + div {
    margin-left: 32vw;
  }
`;

const TweetImage = styled.img`
  width: 100%;
  display: block;
  margin: 0px auto;
  box-sizing: border-box;
`;

const decodeHTML = html => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

const TWEET_DESIGN = 'tweet';

function DefaultTweetData({ author, createdAt, text }) {
  const isTrettonTweet = author.name && author.name === TRETTON37;

  return (
    <TweetData>
      <TweetText>{mapTweet(text)}</TweetText>
      <TweetAuthorContainer>
        <TwitterLogoStyled isTrettonTweet={isTrettonTweet} />
        {author.name && <TweetAuthorSpan isTrettonTweet={isTrettonTweet}>{author.name}</TweetAuthorSpan>}
        {createdAt && <span>{`, ${createdAt}`}</span>}
      </TweetAuthorContainer>
    </TweetData>
  );
}

function NewsTweetData({ author = { name: null, avatarUrl: null }, createdAt, text }) {
  return (
    <TweetData>
      <AuthorContainer>
        {author.avatarUrl && <AuthorAvatar src={`${author.avatarUrl}`} />}
        <NewsAuthor>{author.name}</NewsAuthor>
      </AuthorContainer>
      <NewsText>{cleanText(text)}</NewsText>
      {createdAt ? <Time>{createdAt}</Time> : null}
    </TweetData>
  );
}

export function Tweet(props) {
  const { design, image } = props;

  return (
    <TweetContainer>
      {image && (
        <ImageContainer>
          <TweetImage src={`${image}`} />
        </ImageContainer>
      )}

      {design === TWEET_DESIGN ? <DefaultTweetData {...props} /> : <NewsTweetData {...props} />}
    </TweetContainer>
  );
}
