import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { GraphQLClient, ClientContext } from 'graphql-hooks';
import aws4 from 'aws4';

import App from './components/App';

import { getTokens } from './services/auth';
import { AuthenticationProvider } from './hooks/useAuthentication';
import { MqttMessagesProvider } from './hooks/useMqttMessages';

import config from './config';
import reducer from './reducers';
import rootSaga from './sagas';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const graphQLClient = new GraphQLClient({
  url: `${config.host}${config.basePath}/graphql`,
  fetch: (url, options) => {
    const parsedUrl = new URL(url);
    const signedRequest = aws4.sign(
      {
        host: parsedUrl.hostname,
        path: parsedUrl.pathname,
        region: config.awsRegion,
        service: 'execute-api',
        ...options,
      },
      getTokens()
    );
    return fetch(url, signedRequest);
  },
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, composeEnhancer(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

function AppContainer() {
  return (
    <ClientContext.Provider value={graphQLClient}>
      <MqttMessagesProvider>
        <AuthenticationProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </AuthenticationProvider>
      </MqttMessagesProvider>
    </ClientContext.Provider>
  );
}

ReactDOM.render(<AppContainer />, document.getElementById('root'));

export { store };
