import { store } from '../index';

export function proxyMessageToRedux({ source, action, resource, resourceIdentifier, object }) {
  if (source === 'tv') {
    return;
  }

  const mappedEntity = mapEntity(resource);
  const mappedAction = mapAction(action);

  if (!mappedEntity || !mappedAction) {
    return;
  }

  let reduxAction = {
    type: `${mappedAction}_${mappedEntity}`,
    [resourceIdentifier]: object,
  };
  store.dispatch(reduxAction);
}

function mapEntity(sourceType) {
  switch (sourceType) {
    case 'chat':
      return 'CHAT';
    case 'app':
      return 'APP';
    default:
      return null;
  }
}

function mapAction(sourceAction) {
  switch (sourceAction) {
    case 'create':
      return 'INSERT';
    case 'update':
      return 'UPDATE';
    case 'delete':
      return 'REMOVE';
    case 'change':
      return 'CHANGE';
    case 'reload':
      return 'RELOAD';
    case 'clear':
      return 'CLEAR';
    case 'set':
      return 'SET';
    default:
      return null;
  }
}
