import React from 'react';

import Chat from './Chat';
import { useChats } from '../hooks/useChats';

const Chats = () => {
  const chats = useChats();
  const chatsContainerContent = chats.map(chat => <Chat key={chat.pk} {...chat} />);

  return <div>{chatsContainerContent}</div>;
};

export default Chats;
