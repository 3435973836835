import React from 'react';
import styled from 'styled-components';
import { useAuthentication } from '../hooks/useAuthentication';
import Logo from './Logo';
import Loader from './Loader';

const SMALL_SCREEN_HEIGHT_BREAKPOINT = 700;

const Container = styled.div`
  max-width: 800px;
  margin: 80px auto 0px auto;
  padding: 40px;
`;

const Content = styled.div`
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

const ErrorMessage = styled.div`
  margin: 20px 0;
  color: #f00;
  text-align: center;
  font-weight: bold;
  font-size: 13px;

  @media only screen and (min-height: ${SMALL_SCREEN_HEIGHT_BREAKPOINT}px) {
    margin: 40px 0;
    font-size: 26px;
  }
`;

const NumberContainerTitle = styled.div`
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 20px;

  @media only screen and (min-height: ${SMALL_SCREEN_HEIGHT_BREAKPOINT}px) {
    font-size: 40px;
  }
`;

const NumberContainerSubtitle = styled.div`
  color: white;
  text-align: center;
  margin-top: 5px;
  font-size: 13px;

  @media only screen and (min-height: ${SMALL_SCREEN_HEIGHT_BREAKPOINT}px) {
    margin-top: 10px;
    font-size: 26px;
  }
`;

const NumberContainer = styled.div`
  margin-top: 20px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-height: ${SMALL_SCREEN_HEIGHT_BREAKPOINT}px) {
    height: 220px;
    margin-top: 40px;
  }
`;

const NumberBox = styled.div`
  width: 70px;
  height: 110px;
  font-size: 46px;
  background: rgba(255, 255, 255, 0.2);
  margin: 0 4px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;

  @media only screen and (min-height: ${SMALL_SCREEN_HEIGHT_BREAKPOINT}px) {
    width: 140px;
    height: 220px;
    margin: 0 8px;
    font-size: 92px;
  }
`;

const Register = () => {
  const { registerDevice } = useAuthentication();

  const [activationCode, setActivationCode] = React.useState('');
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  async function submit() {
    try {
      setError(null);
      if (!activationCode) {
        setError('Please enter activation code.');
        return;
      }
      setLoading(true);
      await registerDevice(activationCode);
    } catch (err) {
      setActivationCode('');
      setLoading(false);
      setError(err.response.data.message);
    }
  }

  React.useEffect(() => {
    if (error) {
      setActivationCode('');
      setLoading(false);
    }
  }, [error]);

  React.useEffect(() => {
    const handler = e => {
      if (48 <= e.keyCode && e.keyCode <= 57) {
        const number = String(e.keyCode - 48);
        setActivationCode(activationCode + number);
      }
    };
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [activationCode]);

  React.useEffect(() => {
    if (activationCode.length === 4) {
      submit();
    }
    // skipped in dependency array below: submit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationCode]);

  const activationNumbers = activationCode.padEnd(4, ' ').split('');

  return (
    <Container>
      <Logo onRegister />
      <Content>
        <NumberContainerTitle>Device activation</NumberContainerTitle>
        <NumberContainerSubtitle>Please enter the device activation code</NumberContainerSubtitle>
        <NumberContainer>
          {!loading && activationNumbers.map((num, id) => <NumberBox key={id}>{num}</NumberBox>)}
          {loading && <Loader size="64" />}
        </NumberContainer>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Content>
    </Container>
  );
};

export default Register;
