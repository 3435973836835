import AWS from 'aws-sdk';
import AWSIoTData from 'aws-iot-device-sdk';
import config from '../config';
import { proxyMessageToRedux } from './mqttToReduxProxy';

const awsConfiguration = {
  host: config.awsIoTEndpoint, // 'YourAWSIoTEndpoint', e.g. 'prefix.iot.us-east-1.amazonaws.com'
  region: config.awsRegion, // 'YourAwsRegion', e.g. 'us-east-1'
};

AWS.config.region = awsConfiguration.region;

let currentlySubscribedPrivateTopics = [];

let mqttClient;
let debugMode = false;

export async function connectToMqtt(processMqttMessage) {
  let authData = JSON.parse(localStorage.getItem('authentication'));

  AWS.config.credentials = new AWS.Credentials({
    accessKeyId: authData.accessKeyId,
    secretAccessKey: authData.accessKeySecret,
  });

  var identity = new AWS.STS();
  identity.getSessionToken({}, function(err, data) {
    if (!err) {
      initializeMQTTClient(data, authData.userName, processMqttMessage);
    } else {
      console.log('error retrieving credentials: ' + err);
    }
  });
}

function initializeMQTTClient(data, userName, processMqttMessage) {
  mqttClient = AWSIoTData.device({
    region: AWS.config.region,
    host: awsConfiguration.host,
    clientId: `${userName}${Math.floor(Math.random() * 1000 + 1)}`,
    protocol: 'wss',
    maximumReconnectTimeMs: 8000,
    debug: debugMode,
    accessKeyId: data.Credentials.AccessKeyId,
    secretKey: data.Credentials.SecretAccessKey,
    sessionToken: data.Credentials.SessionToken,
  });
  window.mqttClientConnectHandler = function() {
    mqttClient.subscribe([`${config.stage}/Global`]);
  };

  window.mqttClientReconnectHandler = function() {
    console.log('reconnect');
  };

  window.mqttClientMessageHandler = function(topic, message) {
    if (debugMode) {
      console.log('message: ' + topic + ':' + message.toString());
    }

    const parsedMessage = JSON.parse(message);
    if (parsedMessage) {
      if (parsedMessage.proxyMessageToRedux) {
        proxyMessageToRedux(parsedMessage);
      } else {
        processMqttMessage(parsedMessage);
      }
    }
  };

  mqttClient.on('connect', window.mqttClientConnectHandler);
  mqttClient.on('reconnect', window.mqttClientReconnectHandler);
  mqttClient.on('message', window.mqttClientMessageHandler);
}

export function publishToMQTT(topic, message) {
  if (mqttClient && topic) {
    mqttClient.publish(`${config.stage}/${topic}`, JSON.stringify(message));
  }
}

export function disconnectMQTTClient() {
  if (mqttClient) {
    mqttClient.end(true);
  }
}

export function subscribeToPrivateTopics(topics) {
  let retries = 0;
  if (mqttClient) {
    if (currentlySubscribedPrivateTopics.length) {
      mqttClient.unsubscribe(currentlySubscribedPrivateTopics);
    }
    if (topics && topics.length) {
      const subscribeTopics = topics.map(topic => `${config.stage}/${topic}`);
      currentlySubscribedPrivateTopics = subscribeTopics;
      console.log('subscribing to: ' + subscribeTopics.join(', '));
      mqttClient.subscribe(currentlySubscribedPrivateTopics);
    }
  } else if (topics && topics.length) {
    setTimeout(() => {
      if (retries) console.log(`Retrying subscribing (${retries})`);
      if (retries < 30) retries += 1; // exponential backoff
      subscribeToPrivateTopics(topics);
    }, 1000 * (1 + retries ** 1.2));
  }
}
